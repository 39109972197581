import React, { FC } from 'react';

import BaseListing from '../BaseListing';
import { ListingType } from '../constants';
import { ListingProps } from '../models';

export const LinkListing: FC<ListingProps> = (props) => (
  <BaseListing
    {...{
      type: ListingType.LinkList,
      ...props,
    }}
  />
);

export default LinkListing;
