import { RefObject, useState } from 'react';
import Carousel from 'react-multi-carousel';

import { CAROUSEL_STATE_MOCK, RESPONSIVE } from './constants';
import {
  SMPaddingsProps,
  UpdateAdditionalTransfromParamsProps,
  UpdateAdditionalTransfromProps,
} from './models';

const calcParams = (
  carousel: RefObject<Carousel>,
  params: UpdateAdditionalTransfromProps = {}
): UpdateAdditionalTransfromParamsProps => {
  const { nextSlide, currentState } = params;
  const { deviceType, currentSlide, slidesToShow, totalItems } =
    currentState || carousel.current?.state || CAROUSEL_STATE_MOCK;
  const slide = nextSlide !== undefined ? nextSlide : currentSlide || 0;
  const maxSlideShown = totalItems - slidesToShow;

  return {
    deviceType,
    slide,
    maxSlideShown,
    totalItems,
  };
};

const useAdditionalTransform = (carousel: RefObject<Carousel>, smPaddings: SMPaddingsProps) => {
  const [additionalTransfrom, setAdditionalTransfrom] = useState<number>();

  const additionalTransformsSMMap = {
    first: smPaddings.left,
    middle: RESPONSIVE.sm.partialVisibilityGutter! / 2,
    last: -smPaddings.right,
  };

  const updateAdditionalTransfrom = (params: UpdateAdditionalTransfromProps = {}): void => {
    const { deviceType, slide, maxSlideShown, totalItems } = calcParams(carousel, params);

    const {
      breakpoint: { min, max },
    } = RESPONSIVE.sm;
    const isSM = deviceType === 'sm' || (window.innerWidth >= min && window.innerWidth < max);

    let newTransform;
    if (!isSM || totalItems === 1) {
      newTransform = 0;
    } else if (slide === 0) {
      newTransform = additionalTransformsSMMap.first;
    } else if (slide === maxSlideShown) {
      newTransform = additionalTransformsSMMap.last;
    } else {
      newTransform = additionalTransformsSMMap.middle;
    }
    if (newTransform !== additionalTransfrom) {
      setAdditionalTransfrom(newTransform);
    }
  };

  return { additionalTransfrom, updateAdditionalTransfrom };
};

export default useAdditionalTransform;
