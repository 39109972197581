import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';
import { BazaarVoiceInlineRating } from '@phx-husky/bazaarvoice-components';

import { gridColumnsCount } from 'utils/constants';

import { ProductListingCardProps } from '../models';
import ProductListListingCardContent from './ProductListListingCardContent';

const ProductListingCard: FC<ProductListingCardProps> = ({
  type,
  title,
  description,
  disclaimer,
  image,
  url,
  tags,
  findCallOutTag,
  cardsInRow = 1,
  externalLink,
  useExternalLinks,
  sku,
  ean,
  showBazaarvoiceStarRating,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;
  const callOutTag = findCallOutTag(tags);

  const wrapCompProps = {
    className: `listing-card-wrapper listing-card-wrapper--${type}`,
    'data-testid': 'listing-card',
  };

  const bvProductId = ean || sku;

  return (
    <Col as="li" xl={colSpan} md={6} {...wrapCompProps}>
      {useExternalLinks && externalLink?.length ? (
        <>
          <a
            className={`listing-card listing-card--${type}`}
            href={externalLink}
            aria-label={`${callOutTag ? `${title} - ${callOutTag}` : title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProductListListingCardContent
              title={title}
              description={description}
              disclaimer={disclaimer}
              image={image}
              tags={tags}
              findCallOutTag={findCallOutTag}
            />
          </a>
          {showBazaarvoiceStarRating && bvProductId ? (
            <BazaarVoiceInlineRating productId={bvProductId} seo={false} />
          ) : null}
        </>
      ) : (
        <>
          <Link
            className={`listing-card listing-card--${type}`}
            to={url}
            aria-label={`${callOutTag ? `${title} - ${callOutTag}` : title}`}
          >
            <ProductListListingCardContent
              title={title}
              description={description}
              disclaimer={disclaimer}
              image={image}
              tags={tags}
              findCallOutTag={findCallOutTag}
            />
          </Link>
          {showBazaarvoiceStarRating && bvProductId ? (
            <BazaarVoiceInlineRating productId={bvProductId} seo={false} />
          ) : null}
        </>
      )}
    </Col>
  );
};

export default ProductListingCard;
