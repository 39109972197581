import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';
import { gridColumnsCount } from 'utils/constants';

import { ListingCardProps } from '../models';

const LinkListingCard: FC<ListingCardProps> = ({
  type,
  title,
  description,
  ctaLabel,
  image,
  url,
  isCardClickable,
  cardsInRow,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;

  return (
    <Col
      as="li"
      xl={colSpan}
      md={6}
      className={classNames('listing-card-wrapper', {
        'listing-card-wrapper--clickable': isCardClickable,
      })}
      data-testid="listing-card"
    >
      {isCardClickable ? (
        <div className={`listing-card listing-card--${type}`}>
          <a
            className="listing-card--card-link"
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            title={ctaLabel}
          >
            <Image imageData={image} alt={title || ctaLabel} className="listing-card__image" />
          </a>
          <div className="listing-card__container">
            {title ? (
              <a
                className="listing-card--card-link"
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                title={ctaLabel}
              >
                <h3 className="listing-card__title">{title}</h3>
              </a>
            ) : null}
            {description ? (
              <a
                className="listing-card--card-link"
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                title={ctaLabel}
              >
                <p className="listing-card__description">{description}</p>
              </a>
            ) : null}
            <LinkButton
              text={ctaLabel}
              type={VisualType.Primary}
              url={url}
              ariaLabel={ctaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        </div>
      ) : (
        <div className={`listing-card listing-card--${type}`}>
          <Image imageData={image} alt={title || ctaLabel} className="listing-card__image" />
          <div className="listing-card__container">
            {title ? <h3 className="listing-card__title">{title}</h3> : null}
            {description ? <p className="listing-card__description">{description}</p> : null}
            <LinkButton
              text={ctaLabel}
              type={VisualType.Primary}
              url={url}
              ariaLabel={ctaLabel}
              variant={VisualVariant.Dark}
            />
          </div>
        </div>
      )}
    </Col>
  );
};

export default LinkListingCard;
