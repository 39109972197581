import React, { FC } from 'react';
import classNames from 'classnames';

import { ListingDescriptionProps } from './models';

const ListingDescription: FC<ListingDescriptionProps> = ({ description, fullWidthDescription }) => (
  <p
    data-testid="listing-description"
    className={classNames('listing__description', {
      'listing__description--full-width': fullWidthDescription === '1',
    })}
  >
    {description}
  </p>
);

export default ListingDescription;
