export enum ListingType {
  ACL = 'acl', // Article Category Listing
  Stains = 'stains',
  Expertise = 'expertise',
  ArticlePromo = 'articlePromo',
  ArticleCampaign = 'articleCampaign',
  ProductsCarousel = 'products-carousel',
  ProductsList = 'products-list',
  InfoList = 'info-list',
  LinkList = 'link-list',
  Content = 'content',
}

export enum ProductsListingDisplayType {
  Carousel = 'Carousel',
  ListWithShowMore = 'List with show more',
}

export const MAX_DESCRIPTION_LENGTH = 200;

export const START_ITEMS = 12;

export const LOAD_ITEMS = 12;
