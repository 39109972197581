import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { ProductListingCardContentType } from '../models';

const ProductListListingCardContent: FC<ProductListingCardContentType> = ({
  title,
  description,
  disclaimer,
  image,
  tags,
  findCallOutTag,
}) => {
  const callOutTag = findCallOutTag(tags);

  return (
    <>
      {callOutTag ? (
        <div className="listing-card__call-out" data-testid="listing-card__call-out">
          {callOutTag}
        </div>
      ) : null}
      <Image imageData={image} alt={title || ''} className="listing-card__image" />
      <Row noGutters className="listing-card__top-container">
        <Col className="listing-card__title-container">
          <h3 className="listing-card__title heading-link heading-link--lvl5">{title}</h3>
        </Col>
      </Row>
      <Row className="listing-card__bottom-container">
        <Col className="listing-card__bottom-container-col">
          <p className="listing-card__description">{description}</p>
          <DangerouslySetInnerHtml className="listing-card__disclaimer" html={disclaimer} />
        </Col>
      </Row>
    </>
  );
};

export default ProductListListingCardContent;
