import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import Image from 'common/Image';
import { gridColumnsCount } from 'utils/constants';

import { ListingCardProps } from '../models';

const ExpertiseListingCard: FC<ListingCardProps> = ({
  type,
  title,
  ctaLabel,
  image,
  url,
  cardsInRow,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;

  return (
    <Col as="li" xl={colSpan} md={6} className="listing-card-wrapper" data-testid="listing-card">
      <Link
        className={`listing-card listing-card--${type}`}
        to={url}
        aria-label={`${ctaLabel ? `${title} - ${ctaLabel}` : title}`}
      >
        <Image imageData={image} alt={title || ctaLabel} className="listing-card__image" />
        <div className="listing-card__container">
          <h3 className="listing-card__title heading-link heading-link--lvl4">
            {title || ctaLabel}
          </h3>
        </div>
      </Link>
    </Col>
  );
};

export default ExpertiseListingCard;
