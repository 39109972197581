import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

import Image from 'common/Image';
import { gridColumnsCount } from 'utils/constants';

import { ListingCardProps } from '../models';

const StainsListingCard: FC<ListingCardProps> = ({
  type,
  title,
  description,
  ctaLabel,
  image,
  url,
  cardsInRow,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;

  return (
    <Col as="li" xl={colSpan} md={6} className="listing-card-wrapper" data-testid="listing-card">
      <Link
        className={`listing-card listing-card--${type}`}
        to={url}
        aria-label={`${title && ctaLabel ? `${title} - ${ctaLabel}` : ctaLabel || title}`}
      >
        <Image imageData={image} alt={title || ctaLabel} className="listing-card__image" />
        <div className="listing-card__container">
          {title ? <h3 className="listing-card__title">{title}</h3> : null}
          {description ? <p className="listing-card__description">{description}</p> : null}
          <p className="listing-card__cta heading-link heading-link--lvl4">{ctaLabel}</p>
        </div>
      </Link>
    </Col>
  );
};

export default StainsListingCard;
