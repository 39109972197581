import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Image from 'common/Image';

import { ListingCardProps } from '../models';

const ArticleCampaignListingCard: FC<ListingCardProps> = ({
  type,
  title,
  description,
  ctaLabel,
  image,
  promoImage,
  url,
  cardsInRow,
}) => (
  <div className={`listing-card-wrapper listing-card-wrapper--${type}`} data-testid="listing-card">
    <Link
      className={classNames(`listing-card listing-card--${type}`, {
        [`listing-card--${cardsInRow}-in-row`]: cardsInRow,
      })}
      to={url}
      aria-label={`${ctaLabel ? `${title} - ${ctaLabel}` : title}`}
    >
      <Image
        imageData={promoImage || image}
        alt={title || ctaLabel}
        className="listing-card__image"
      />
      <div className="listing-card__container">
        <h3 className="listing-card__title heading-link heading-link--lvl3">{title || ctaLabel}</h3>
        <p className="listing-card__description">{description}</p>
      </div>
    </Link>
  </div>
);

export default ArticleCampaignListingCard;
