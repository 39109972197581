import React, { FC } from 'react';

import { ProductsListingDisplayType } from '../constants';
import { ListingProps } from '../models';
import ProductsCarouselListing from './ProductsCarouselListing';
import ProductsListListing from './ProductsListListing';
import useFindCallOutTag from './useFindCallOutTag';

export const ProductsListing: FC<ListingProps> = (props) => {
  const { displayMode } = props;
  const { findCallOutTag } = useFindCallOutTag();

  return displayMode === ProductsListingDisplayType.ListWithShowMore ? (
    <ProductsListListing {...{ ...props, findCallOutTag }} />
  ) : (
    <ProductsCarouselListing {...{ ...props, findCallOutTag }} />
  );
};

export default ProductsListing;
