import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseListing from '../BaseListing';
import { ListingType, LOAD_ITEMS, START_ITEMS } from '../constants';
import { ListingProps } from '../models';

export const StainsListing: FC<ListingProps> = (props) => {
  const {
    sharedComponentsSettings: { stainsListShowMoreLabel },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        stainsListShowMoreLabel
      }
    }
  `);

  return (
    <BaseListing
      {...{
        type: ListingType.Stains,
        ...props,
        loadMore: {
          label: stainsListShowMoreLabel,
          startItems: START_ITEMS,
          loadItems: LOAD_ITEMS,
        },
      }}
    />
  );
};

export default StainsListing;
