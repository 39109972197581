import { ResponsiveType } from 'react-multi-carousel';

import { LAYOUT_BREAKPOINTS } from 'utils/constants';

import smPaddingsVariables from './Carousel.module.scss';

export const RESPONSIVE: ResponsiveType = {
  sm: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.md, min: 0 },
    items: 1,
    partialVisibilityGutter: 51,
  },
  xl: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.xl, min: LAYOUT_BREAKPOINTS.md },
    items: 2,
    slidesToSlide: 2,
  },
  lg: {
    breakpoint: { max: 100000, min: LAYOUT_BREAKPOINTS.xl },
    items: 4,
    slidesToSlide: 4,
  },
};

export const RESPONSIVETHREE: ResponsiveType = {
  sm: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.md, min: 0 },
    items: 1,
    partialVisibilityGutter: 51,
  },
  xl: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.xl, min: LAYOUT_BREAKPOINTS.md },
    items: 2,
    slidesToSlide: 2,
  },
  lg: {
    breakpoint: { max: 100000, min: LAYOUT_BREAKPOINTS.xl },
    items: 3,
    slidesToSlide: 3,
  },
};

export const DEFAULT_SM_PADDINGS = {
  left: parseInt(smPaddingsVariables.smPaddingLeft, 10) || 0,
  right: parseInt(smPaddingsVariables.smPaddingsRight, 10) || 0,
};

export const CAROUSEL_STATE_MOCK = {
  deviceType: '',
  currentSlide: 0,
  slidesToShow: 0,
  totalItems: 0,
  itemWidth: 0,
  transform: 0,
};
