// Based on react-multi-carousel WithScrollbar story: https://github.com/YIZHUANG/react-multi-carousel/blob/master/stories/WithScrollbar.js
import React, { FC, useEffect, useRef, useState } from 'react';
import { Range } from 'react-range';
import { graphql, useStaticQuery } from 'gatsby';

import { CAROUSEL_STATE_MOCK } from '../constants';
import CarouselButton from './CarouselButton';
import { CarouselControlsProps } from './models';

import './CarouselControls.scss';

const stepsPerSlide = 10;

const CarouselControls: FC<CarouselControlsProps> = ({
  previous,
  next,
  carouselState,
  carouselRef,
  updateAdditionalTransfrom,
}) => {
  const {
    sharedComponentsSettings: { carouselPrevLabel, carouselNextLabel, carouselSliderLabel },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        carouselPrevLabel
        carouselNextLabel
        carouselSliderLabel
      }
    }
  `);

  const { currentSlide, itemWidth, totalItems, slidesToShow } =
    carouselState || CAROUSEL_STATE_MOCK;

  const slidesMax = totalItems - slidesToShow;
  const rangeMax = slidesMax * itemWidth;
  const sliderStep = itemWidth / stepsPerSlide;
  const [values, setValues] = useState([0]);
  const rangeRef = useRef<HTMLDivElement>(null);
  const [thumbWidth, setThumbWidth] = useState(0);

  useEffect(() => {
    const { transform } = carouselState || CAROUSEL_STATE_MOCK;
    setValues([-transform]);
  }, [carouselState]);

  useEffect(() => {
    if (!rangeRef.current) return;

    const { totalItems: tot, slidesToShow: sts } = carouselState || CAROUSEL_STATE_MOCK;
    const rangeWidth = rangeRef.current!.offsetWidth;

    setThumbWidth((rangeWidth / tot) * sts);
  }, [carouselState, rangeRef.current]);

  return rangeMax > 0 ? (
    <div className="carousel-controls" data-testid="carousel-controls">
      <div className="carousel-slider" data-testid="carousel-slider" ref={rangeRef}>
        <Range
          min={0}
          max={rangeMax}
          step={sliderStep}
          values={values}
          onChange={([val]) => {
            setValues([val]);
            const nextTransform = val;
            const nextSlide = Math.round(val / itemWidth);
            updateAdditionalTransfrom({ nextSlide, currentState: carouselState });
            carouselRef.current.setState({
              transform: -nextTransform,
              currentSlide: nextSlide,
            });
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="carousel-slider__outer-track"
              style={{
                ...props.style,
              }}
              role="none"
            >
              <div className="carousel-slider__middle-track">
                <div
                  className="carousel-slider__inner-track"
                  ref={props.ref}
                  style={{ margin: `0 ${thumbWidth / 2}px` }}
                >
                  {children}
                </div>
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            // Proper role and aria-value properties are added inside of module via ...props
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              {...props}
              // Key events behavior is controlled by Carousel itself
              onKeyDown={() => {}}
              onKeyUp={() => {}}
              className="carousel-slider__thumb"
              style={{
                ...props.style,
                width: thumbWidth,
              }}
              aria-label={carouselSliderLabel}
            />
          )}
        />
      </div>

      <div className="carousel-buttons" data-testid="carousel-buttons">
        <CarouselButton
          onClick={previous}
          label={carouselPrevLabel}
          direction="left"
          disabled={currentSlide === 0}
        />
        <CarouselButton
          onClick={next}
          label={carouselNextLabel}
          direction="right"
          disabled={currentSlide === slidesMax}
        />
      </div>
    </div>
  ) : null;
};

export default CarouselControls;
