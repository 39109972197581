import { graphql, useStaticQuery } from 'gatsby';

import { FindCallOutTagFunction } from '../models';

const useFindCallOutTag = () => {
  const {
    sharedComponentsSettings: { productCallOutTags },
  } = useStaticQuery(graphql`
    {
      sharedComponentsSettings {
        productCallOutTags
      }
    }
  `);

  const findCallOutTag: FindCallOutTagFunction = (tags) => {
    if (!tags?.length) return undefined;

    for (let i = 0; i < productCallOutTags.length; ++i) {
      const id = productCallOutTags[i];
      const foundTag = tags.find((tag) => tag.id === id);
      if (foundTag) return foundTag?.title || foundTag.name;
    }

    return undefined;
  };

  return { findCallOutTag };
};

export default useFindCallOutTag;
