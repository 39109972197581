import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { navigate } from 'gatsby';
import { BazaarVoiceInlineRating } from '@phx-husky/bazaarvoice-components';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { ProductListingCardProps } from '../models';

const ProductListingCard: FC<ProductListingCardProps> = ({
  type,
  title,
  description,
  disclaimer,
  image,
  url,
  tags,
  isCarouselMoving,
  findCallOutTag,
  onFocus,
  sku,
  ean,
  showBazaarvoiceStarRating,
}) => {
  const callOutTag = findCallOutTag(tags);

  const onClick = (e) => {
    if (isCarouselMoving) e.preventDefault();
    // ignore clicks with modifier keys... See https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-link/src/index.js#L215
    else if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return true;
    else navigate(url);
  };

  const bvProductId = ean || sku;

  return (
    <div
      className={`listing-card-wrapper listing-card-wrapper--${type}`}
      data-testid="listing-card"
    >
      <a
        className={classNames(`listing-card listing-card--${type}`, {
          [`listing-card--bazaar-rating`]: showBazaarvoiceStarRating,
        })}
        onClick={onClick}
        href={url}
        onFocus={onFocus}
        aria-label={`${callOutTag ? `${title} - ${callOutTag}` : title}`}
      >
        {callOutTag ? (
          <div className="listing-card__call-out" data-testid="listing-card__call-out">
            {callOutTag}
          </div>
        ) : null}
        <Image imageData={image} alt={title || ''} className="listing-card__image" />
        <Row noGutters className="listing-card__top-container">
          <Col className="listing-card__title-container">
            <h3 className="listing-card__title heading-link heading-link--lvl5">{title}</h3>
          </Col>
        </Row>
        <Row className="listing-card__bottom-container">
          <Col className="listing-card__bottom-container-col">
            <p className="listing-card__description">{description}</p>
            <DangerouslySetInnerHtml className="listing-card__disclaimer" html={disclaimer} />
          </Col>
        </Row>
      </a>
      {showBazaarvoiceStarRating && bvProductId ? (
        <BazaarVoiceInlineRating productId={bvProductId} seo={false} />
      ) : null}
    </div>
  );
};

export default ProductListingCard;
