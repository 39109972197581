import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VisualType, VisualVariant } from 'common/Button';
import CustomCarousel from 'common/CustomCarousel';
import LinkButton from 'components/LinkButton';

import { ListingType } from '../constants';
import ListingCard from '../ListingCard';
import { ListingProps, ProductListingCardType } from '../models';

import '../Listing.scss';

export const ProductsListingCarousel: FC<ListingProps> = ({
  title,
  description,
  cta: ctaProp,
  cards,
  findCallOutTag,
  ctaAriaLabel,
}) => {
  const [cta] = ctaProp || [];
  const type = ListingType.ProductsCarousel;

  return (
    <Row className={`listing listing--${type}`} data-testid="products-carousel-listing">
      <Col className="listing__col">
        <h2 className="listing__title">{title}</h2>
        {description ? <p className="listing__description">{description}</p> : null}
        <div className="listing__product-cards">
          <CustomCarousel>
            {(cards as ProductListingCardType[]).map((card) => (
              <ListingCard key={card.url} {...{ ...card, type, findCallOutTag: findCallOutTag! }} />
            ))}
          </CustomCarousel>
        </div>
        {cta ? (
          <div className="listing__cta">
            <LinkButton
              text={cta.name}
              url={cta.url}
              ariaLabel={ctaAriaLabel}
              type={VisualType.Discover}
              variant={VisualVariant.Dark}
            />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default ProductsListingCarousel;
