import React, { FC } from 'react';
import classNames from 'classnames';

import { CarouselButtonProps } from './models';

const CarouselButton: FC<CarouselButtonProps> = ({
  onClick,
  label,
  direction,
  disabled = false,
}) => (
  <button
    type="button"
    className={classNames(`carousel-buttons__button carousel-buttons__button--${direction}`, {
      'carousel-buttons__button--disabled': disabled,
    })}
    data-testid="carousel-button"
    onClick={onClick}
    aria-label={label}
    disabled={disabled}
  />
);

export default CarouselButton;
