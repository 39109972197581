import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import { gridColumnsCount } from 'utils/constants';

import { InfoListingCardProps } from '../models';

const InfoListingCard: FC<InfoListingCardProps> = ({
  type,
  title,
  description,
  subDescription,
  cardsInRow = 1,
}) => {
  const colSpan = gridColumnsCount / cardsInRow;

  const wrapCompProps = {
    className: `listing-card-wrapper listing-card-wrapper--${type}`,
    'data-testid': 'listing-card',
  };

  return (
    <Col as="li" xl={colSpan} md={6} {...wrapCompProps}>
      <div className={`listing-card listing-card--${type}`}>
        <Row noGutters className="listing-card__top-container">
          <Col className="listing-card__title-container">
            <h3 className="listing-card__title heading-link heading-link--lvl5">{title}</h3>
          </Col>
        </Row>
        <Row className="listing-card__bottom-container">
          <Col className="listing-card__bottom-container-col">
            <DangerouslySetInnerHtml html={description} className="listing-card__description" />
          </Col>
        </Row>
        <Row className="listing-card__bottom-container">
          <Col className="listing-card__bottom-container-col">
            <p className="listing-card__sub-description">{subDescription}</p>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default InfoListingCard;
