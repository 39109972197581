import React, { FC } from 'react';

import { ListingType, MAX_DESCRIPTION_LENGTH } from '../constants';
import { ListingCardProps, ProductListingCardProps } from '../models';
import ACLListingCard from './ACLListingCard';
import ArticleCampaignListingCard from './ArticleCampaignListingCard';
import ArticlePromoListingCard from './ArticlePromoListingCard';
import ContentCard from './ContentCard';
import ExpertiseListingCard from './ExpertiseListingCard';
import InfoListingCard from './InfoListingCard';
import LinkListingCard from './LinkListingCard';
import ProductCarouselListingCard from './ProductCarouselListingCard';
import ProductListListingCard from './ProductListListingCard';
import StainsListingCard from './StainsListingCard';

import './ListingCard.scss';
import './ShadowListingCard.scss';

const cards = {
  [ListingType.ACL]: ACLListingCard,
  [ListingType.Stains]: StainsListingCard,
  [ListingType.Expertise]: ExpertiseListingCard,
  [ListingType.ArticlePromo]: ArticlePromoListingCard,
  [ListingType.ArticleCampaign]: ArticleCampaignListingCard,
  [ListingType.ProductsList]: ProductListListingCard,
  [ListingType.InfoList]: InfoListingCard,
  [ListingType.LinkList]: LinkListingCard,
  [ListingType.Content]: ContentCard,
};

const ListingCard: FC<ListingCardProps | ProductListingCardProps> = (props) => {
  const { type, description, fullDescription } = props;
  const Card = cards[type];
  let trimmedDescritpion =
    description && description.length > MAX_DESCRIPTION_LENGTH
      ? `${description.substring(0, description.lastIndexOf(' ', MAX_DESCRIPTION_LENGTH))}…`
      : description;

  trimmedDescritpion = fullDescription && description ? description : trimmedDescritpion;

  return type === ListingType.ProductsCarousel ? (
    <ProductCarouselListingCard
      {...{ ...(props as ProductListingCardProps), description: trimmedDescritpion }}
    />
  ) : (
    <Card {...{ ...props, description: trimmedDescritpion }} />
  );
};

export default ListingCard;
